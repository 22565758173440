import React from "react"
import { motion, AnimatePresence } from "framer-motion"
import { PortableText } from "@portabletext/react"

//components
import Cross from "./cross"

//Portable text components
const portableComponents = {
  block: {
    normal: ({ children }) => <p className="pb-lead-1 last:pb-0">{children}</p>,
  },
  marks: {
    em: ({ children }) => <em className="italic">{children}</em>,
    strong: ({ children }) => (
      <span className="text-love-grey">{children}</span>
    ),
  },
}

const modalTransition = {
  duration: 0.6,
  ease: [0.85, 0, 0.15, 1],
}

const modalVariants = {
  closed: {
    x: "100%",
    transition: {
      ...modalTransition,
    },
  },
  open: {
    x: "0",
    transition: {
      ...modalTransition,
    },
  },
}

const ProjectInfoModal = ({
  infoModalOpen,
  setInfoModalOpen,
  additionalFields,
  projectDate,
  projectType,
  projectClient,
  projectDescription,
}) => {
  return (
    <>
      <motion.div
        initial="closed"
        animate={infoModalOpen ? "open" : "closed"}
        variants={modalVariants}
        className="fixed top-0 right-0 z-50 w-full h-full bg-white md:w-1/3 shadow-side-modal"
      >
        <button
          onClick={() => setInfoModalOpen(false)}
          className="fixed right-lead-1 top-lead-1"
        >
          <Cross />
        </button>
        {/* Info Contents */}
        <div className="h-full overflow-scroll p-lead-1 scrollbar-hide text-mobile-small md:text-desktop-small">
          <div className="grid grid-cols-4 gap-lead-1 pb-lead-1">
            <h4 className="uppercase text-love-grey">DATE</h4>
            <div className="col-span-3">{projectDate}</div>
          </div>
          <div className="grid grid-cols-4 gap-lead-1 pb-lead-1">
            <h4 className="uppercase text-love-grey">CLIENT</h4>
            <div className="col-span-3">{projectClient}</div>
          </div>
          <div className="grid grid-cols-4 gap-lead-1 pb-lead-1">
            <h4 className="uppercase text-love-grey">Category</h4>
            <div className="col-span-3 capitalize">{projectType}</div>
          </div>
          {additionalFields.map(field => (
            <div
              key={field._key}
              className="grid grid-cols-4 gap-lead-1 pb-lead-1"
            >
              <h4 className="uppercase text-love-grey">
                {field.additionalFieldTitle}
              </h4>
              <div className="col-span-3">
                <PortableText
                  value={field._rawAdditionalFieldContent}
                  components={portableComponents}
                />
              </div>
            </div>
          ))}
          <div className="grid grid-cols-4 gap-lead-1 pb-lead-1">
            {/* <h4 className="uppercase text-love-grey">INFO</h4> */}
            <div className="col-span-4">
              <PortableText
                value={projectDescription}
                components={portableComponents}
              />
            </div>
          </div>
        </div>
      </motion.div>
      {/* info modal overlay */}
      <AnimatePresence>
        {infoModalOpen && (
          <motion.div
            initial={{
              opacity: 0,
            }}
            animate={{
              opacity: 0.7,
            }}
            exit={{
              opacity: 0,
            }}
            className="absolute inset-0 z-40 bg-white cursor-pointer"
            onClick={() => setInfoModalOpen(false)}
          ></motion.div>
        )}
      </AnimatePresence>
    </>
  )
}

export default ProjectInfoModal
