import React, { useState } from "react"
import { Link, graphql } from "gatsby"
import { motion } from "framer-motion"
import Seo from "../../components/seo"

//components
import Cross from "../../components/cross"
import ProjectImageSlider from "../../components/projectImageSlider"
import ProjectInfoModal from "../../components/projectInfoModal"
import Layout from "../../components/layout"

//utils
import { variants } from "../../utils/framerUtils"

const Project = ({ data, location }) => {
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  // const [from, setFrom] = useState(location?.state?.referrer)
  // console.log("referrer:", location?.state?.referrer)
  return (
    <Layout>
      {/* overflow wrapper for page fade in up animations */}
      <div className="absolute top-0 left-0 w-full h-full overflow-hidden">
        <motion.div
          variants={variants.fadeUpandInStaggerChildren}
          className="h-full p-lead-1 font-regular"
        >
          {/* UI */}
          <h1 className="font-light text-mobile-medium md:text-desktop-medium leading-medium">
            <span>{data.sanityProject.title}</span>
            <span className="text-love-grey ml-[0.5em]">
              {data.sanityProject.projectYear}
            </span>
          </h1>
          <div className="fixed z-30 right-lead-1 top-lead-1">
            <Link to="/projects">
              <Cross />
            </Link>
          </div>
          <div
            // className="fixed z-30 bottom-lead-1 right-lead-1 md:top-lead-1 md:left-lead-1 md:w-[calc((100%/12*3)-1.4rem)] md:h-[calc(100vh-2.8rem)] flex flex-col justify-center pointer-events-none"
            className="fixed z-30 bottom-lead-1 right-lead-1"
          >
            <button
              onClick={() => setInfoModalOpen(true)}
              className="font-light pointer-events-auto md:text-desktop-medium text-mobile-medium leading-medium text-love-grey hover:text-black w-max"
            >
              PROJECT INFO
            </button>
          </div>
          {/* Slider */}
          <ProjectImageSlider images={data.sanityProject.projectImages} />
          {/* info modal */}
          <ProjectInfoModal
            infoModalOpen={infoModalOpen}
            setInfoModalOpen={setInfoModalOpen}
            additionalFields={data.sanityProject.additionalFields}
            projectDate={data.sanityProject.projectYear}
            projectClient={data.sanityProject.projectClient}
            projectType={data.sanityProject.projectType}
            projectDescription={data.sanityProject._rawProjectDescription}
          />
        </motion.div>
      </div>
    </Layout>
  )
}

export default Project
export const Head = ({ data }) => <Seo title={data.sanityProject.title} />
export const query = graphql`
  query ($id: String) {
    sanityProject(id: { eq: $id }) {
      title
      projectYear
      projectClient
      projectType
      _rawProjectDescription
      projectImages {
        asset {
          gatsbyImageData
          id
          metadata {
            dimensions {
              aspectRatio
            }
          }
        }
        _rawCaption
      }
      additionalFields {
        additionalFieldTitle
        _rawAdditionalFieldContent
        _key
      }
    }
  }
`
