import React, { useState, useEffect, useCallback } from "react"
import useEmblaCarousel from "embla-carousel-react"
import { GatsbyImage } from "gatsby-plugin-image"
import { PortableText } from "@portabletext/react"

//Portable text components
const portableComponents = {
  marks: {
    em: ({ children }) => <em className="italic">{children}</em>,
    strong: ({ children }) => (
      <span className="text-love-grey">{children}</span>
    ),
  },
}

const ProjectImageSlider = ({ images }) => {
  const [currentSlide, setCurrentSlide] = useState(null)
  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true })

  //change slides callbacks
  const scrollPrev = useCallback(
    () => emblaApi && emblaApi.scrollPrev(),
    [emblaApi]
  )
  const scrollNext = useCallback(
    () => emblaApi && emblaApi.scrollNext(),
    [emblaApi]
  )
  //set slide number callback
  const onScroll = useCallback(() => {
    if (!emblaApi) return
    setCurrentSlide(emblaApi.selectedScrollSnap() + 1)
  }, [emblaApi, setCurrentSlide])

  useEffect(() => {
    if (emblaApi) {
      onScroll()
      emblaApi.on("select", onScroll)
    }
    return () => {
      if (emblaApi) {
        emblaApi.destroy()
      }
    }
  }, [emblaApi, onScroll])
  return (
    <>
      {/* Slider number */}
      <div className="fixed font-light left-lead-1 bottom-lead-1 text-mobile-medium md:text-desktop-medium">
        <span>{currentSlide}</span>
        <span className="text-love-grey ml-[0.5em]">{images.length}</span>
      </div>
      {/* Slider Buttons */}
      {images.length > 1 && (
        <>
          <button
            className="absolute top-0 left-0 z-20 w-1/2 h-full"
            aria-label="Previous image"
            onClick={() => scrollPrev()}
            style={{
              cursor: `url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjE4LjAwNjMiIHkxPSI5LjA5NTciIHgyPSIwLjMyMTc3NyIgeTI9IjkuMDk1NyIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPGxpbmUgeDE9IjkuMTgxNzYiIHkxPSIwLjc1NjI2IiB4Mj0iMC44NDUyNzQiIHkyPSI4Ljg4MzY3IiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8bGluZSB5MT0iLTAuNzUiIHgyPSIxMS42NDI3IiB5Mj0iLTAuNzUiIHRyYW5zZm9ybT0ibWF0cml4KC0wLjcxNjAyOCAtMC42OTgwNzEgLTAuNjk4MDcxIDAuNzE2MDI4IDguNjU4NjkgMTcuOTc2MSkiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41Ii8+Cjwvc3ZnPgo=), w-resize`,
            }}
          ></button>
          <button
            className="absolute top-0 right-0 z-20 w-1/2 h-full"
            aria-label="Next image"
            onClick={() => scrollNext()}
            style={{
              cursor: `url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTkiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxOSAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPGxpbmUgeDE9IjAuMzIxNzc3IiB5MT0iOS4wOTk2MSIgeDI9IjE4LjAwNjMiIHkyPSI5LjA5OTYxIiBzdHJva2U9ImJsYWNrIiBzdHJva2Utd2lkdGg9IjEuNSIvPgo8bGluZSB4MT0iOS4xNDYzNyIgeTE9IjE3LjQzOTEiIHgyPSIxNy40ODI4IiB5Mj0iOS4zMTE2NCIgc3Ryb2tlPSJibGFjayIgc3Ryb2tlLXdpZHRoPSIxLjUiLz4KPGxpbmUgeTE9Ii0wLjc1IiB4Mj0iMTEuNjQyNyIgeTI9Ii0wLjc1IiB0cmFuc2Zvcm09Im1hdHJpeCgwLjcxNjAyOSAwLjY5ODA3MSAwLjY5ODA3MSAtMC43MTYwMjkgOS42Njk0MyAwLjIxOTIzOCkiIHN0cm9rZT0iYmxhY2siIHN0cm9rZS13aWR0aD0iMS41Ii8+Cjwvc3ZnPgo=), e-resize`,
            }}
          ></button>
        </>
      )}
      {/* Embla viewport */}
      <div
        className="absolute top-0 left-0 z-30 w-full h-full overflow-hidden pointer-events-none"
        ref={emblaRef}
      >
        {/* Slides container */}
        <div className="flex items-center h-full">
          {images.map(image => {
            return (
              <div id={image.asset.id} className="h-full min-w-full">
                {/* Max-width wrapper */}
                <div className="md:max-w-[70%] max-w-[90%] h-full mx-auto flex items-center">
                  {/* Wrapper to ensure caption lines up with image edge and set max height */}
                  <div
                    className="mx-auto pointer-events-auto"
                    style={{
                      width: `calc(${image.asset.metadata.dimensions.aspectRatio} * 73vh)`,
                    }}
                  >
                    {/* Wrapper so that shadow only added around image */}
                    <div className="shadow-image mb-lead-1">
                      <GatsbyImage
                        image={image.asset.gatsbyImageData}
                        alt="placeholder alt text"
                        objectFit={"contain"}
                      />
                    </div>
                    {/* 0 height so that it doesn't affect vertical centering */}
                    <div className="h-0 font-regular text-mobile-small md:text-desktop-small">
                      <PortableText
                        value={image?._rawCaption}
                        components={portableComponents}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default ProjectImageSlider
